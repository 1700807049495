import React, { useEffect, useState } from "react";
import { randomString } from "../../services/service";
import Textarea from "../ui/Textarea/Textarea";

export default function RoomComponent({
  room,
  index,
  removeRoom,
  updateField,
}) {
  const [numberRoom, setNumberRoom] = useState(room.Number_room);

  return (
    <div>
      <div className="brif__field">
        <div className="brif__field--inner">
          <div className="brif__rooms-top">
            <h3 className="brif__rooms-title">
              Помещение <span>№{numberRoom}</span>
            </h3>
            <button
              className="brif-btn brif-btn--delete hide-on-print"
              onClick={() => removeRoom(index)}
            >
              Удалить помещение
            </button>
          </div>
        </div>
      </div>
      <div className="brif__field">
        <label className="brif__field-text brif__field--inner">
          <span className="brif__field-text-label">Номер помещения</span>
          <input
            type="number"
            onInput={(e) => {
              updateField(e, room, "Number_room");
              setNumberRoom(e.target.value);
            }}
            className="brif__field-text-input"
            defaultValue={room.Number_room}
            min={0}
            max={99999}
          />
        </label>
      </div>
      <div className="brif__field">
        <label className="brif__field-text brif__field--inner">
          <span className="brif__field-text-label">Название помещения</span>
          <Textarea
            onChange={(e) => updateField(e, room, "Name")}
            className="brif__field-text-input"
            defaultValue={room.Name}
          />
        </label>
      </div>
      <div className="brif__field">
        <label className="brif__field-text brif__field--inner">
          <span className="brif__field-text-label">Площадь помещения</span>
          <input
            type="number"
            onChange={(e) => updateField(e, room, "Size")}
            className="brif__field-text-input"
            defaultValue={room.Size}
            min={0}
            max={99999}
          />
        </label>
      </div>
      <div className="brif__field">
        <label className="brif__field-text brif__field--inner">
          <span className="brif__field-text-label">Описание помещения</span>
          <Textarea
            onChange={(e) => updateField(e, room, "Description")}
            className="brif__field-text-input"
            defaultValue={room.Description}
          />
        </label>
      </div>
      <div className="brif__line"></div>
    </div>
  );
}
