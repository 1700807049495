import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
    get_content,
    init_brif,
    randomString,
    update_brif,
    update_brif_field,
} from "../services/service";
import { calculateHeight } from "../utils/formUtils";

import Fancybox from "./widgets/Fancybox/Fancybox";
import Textarea from "./ui/Textarea/Textarea";
import RadioButtons from "./ui/RadioButtons/RadioButtons";
import TextComponent from "./brifBlocks/TextComponent";
import TextAreaComponent from "./brifBlocks/TextAreaComponent";
import CheckboxComponent from "./brifBlocks/CheckBoxComponent";
import PreBlock from "./brifBlocks/PreBlock";
import GalleryCheckBoxesComponent from "./brifBlocks/GalleryCheckBoxesComponent";
import CheckBoxesComponent from "./brifBlocks/CheckBoxesComponent";
import RoomsComponent from "./brifBlocks/RoomsComponent";
// import axios from 'axios';

const Brif = (props) => {
    const { UID } = useParams();
    const [data, setData] = useState(null);
    const [content, setContent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [id, setId] = useState(null);
    const { slug } = props.data;
    let timer = null;

    const updateData = () => {
        clearTimeout(timer);

        const newTimer = setTimeout(() => {
            update_brif(data, id, slug);
        }, 1000);

        timer = newTimer;
    };

    useEffect(() => {
        const getData = async () => {
            const res = await init_brif(UID, slug);
            const res_content = await get_content(`${slug}-content`);

            if (res.data !== null && res_content.data !== null) {
                setData(res.data[0].attributes);
                setContent(res_content.data.attributes);
                setId(res.data[0].id);

                setLoading(false);
            }
        };

        getData();
    }, [UID]);

    if (!loading) {
        console.log(data);
    }

    const copyToClipboard = () => {
        navigator.clipboard.writeText(window.location.href).then(
            function () {
                alert("Успешно скопировано в буфер обмена!");
            },
            function (err) {
                alert("Упс... Что-то пошло не так!");
            },
        );
    };

    const updateInputData = (e, field) => {
        if (e.target.value.length < 1) {
            e.target.style.width = "10ch";
        } else {
            e.target.style.width = e.target.value.length + "ch";
        }
        data[field] = e.target.value;
        updateData();
    };

    const updateDataTextField = (e, field) => {
        field.Value = e.target.value;
        calculateHeight(e.target);
        updateData();
    };

    const updateCheckboxField = (field, check) => {
        field.Checked = check;
        updateData();
    };

    const updateCheckboxFieldText = (e, field) => {
        field.Text = e.target.value;
        calculateHeight(e.target);
        updateData();
    };

    const updateTextFieldKey = (e, field, key) => {
        field[key] = e.target.value;
        updateData();
    };

    const updateSelectField = (value, field, key) => {
        field[key] = value;
        updateData();
    };

    const updateListRooms = (field, newRooms) => {
        Object.assign(field, newRooms);
        updateData();
    };

    const renderFields = (arr) => {
        return arr.map((field) => {
            const fieldName = randomString(10, "aA#");
            // Controls Fields
            if (field.__component === "global.text-component") {
                return (
                    <TextComponent
                        key={fieldName}
                        field={field}
                        updateField={updateDataTextField}
                    />
                );
            }

            if (field.__component === "global.textarea-field") {
                return (
                    <TextAreaComponent
                        key={fieldName}
                        field={field}
                        updateField={updateDataTextField}
                    />
                );
            }

            if (field.__component === "global.checkbox") {
                return (
                    <CheckboxComponent
                        key={fieldName}
                        field={field}
                        fieldName={fieldName}
                        updateSelectField={updateSelectField}
                        updateCheckboxFieldText={updateCheckboxFieldText}
                    />
                );
            }

            if (field.__component === "global.checkboxes-field") {
                return (
                    <CheckBoxesComponent
                        key={fieldName}
                        field={field}
                        fieldName={fieldName}
                        updateCheckboxField={updateSelectField}
                        updateSelectField={updateSelectField}
                        updateCheckboxFieldText={updateCheckboxFieldText}
                    />
                );
            }

            if (field.__component === "global.colors-field") {
                return (
                    <div className="brif__field" key={fieldName}>
                        <div className="brif__field--inner brif__field-colors">
                            <h4 className="brif__field-text-label">{field.Label}</h4>
                            <ul className="brif__field-colors-list">
                                {field.List.map((item) => {
                                    const itemKey = randomString(6, "aA#");
                                    const image_data = item.Image;

                                    if (image_data) {
                                        const image = `${process.env.REACT_APP_STRAPI_URL}${image_data[0].attributes.url}`;
                                        return (
                                            <li
                                                className="brif__field-colors-list-item"
                                                key={itemKey}
                                            >
                                                <label className="brif__field-colors-label">
                                                    <input
                                                        type="checkbox"
                                                        onClick={(e) =>
                                                            updateCheckboxField(item, e.target.checked)
                                                        }
                                                        defaultChecked={item.Checked}
                                                    />
                                                    <span>
                                                        <svg
                                                            width="29"
                                                            height="29"
                                                            viewBox="0 0 29 29"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M14.5 28C18.0804 28 21.5142 26.5777 24.0459 24.0459C26.5777 21.5142 28 18.0804 28 14.5C28 10.9196 26.5777 7.4858 24.0459 4.95406C21.5142 2.42232 18.0804 1 14.5 1C10.9196 1 7.4858 2.42232 4.95406 4.95406C2.42232 7.4858 1 10.9196 1 14.5C1 18.0804 2.42232 21.5142 4.95406 24.0459C7.4858 26.5777 10.9196 28 14.5 28Z"
                                                                stroke="#404AF2"
                                                                strokeWidth="2"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                            <path
                                                                d="M9.00015 14.0292L13.4374 18.4663"
                                                                stroke="#404AF2"
                                                                strokeWidth="2"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                            <path
                                                                d="M13.5617 18.5909L21.591 10.5616"
                                                                stroke="#404AF2"
                                                                strokeWidth="2"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                    </span>
                                                    <img src={image} alt="Color" />
                                                </label>
                                            </li>
                                        );
                                    }
                                })}
                            </ul>
                        </div>
                    </div>
                );
            }

            if (field.__component === "global.images-field") {
                return (
                    <GalleryCheckBoxesComponent
                        key={fieldName}
                        field={field}
                        updateCheckboxField={updateCheckboxField}
                    />
                );
            }

            if (field.__component === "global.family-field") {
                return (
                    <div className="brif__field" key={fieldName}>
                        <div className="brif__field--inner brif__field-family">
                            <ul className="brif__field-family-list">
                                <li className="brif__field-family-list-item">
                                    <p className="brif__field-family-title">Член семьи</p>
                                    <p className="brif__field-family-title">Возраст</p>
                                    <p className="brif__field-family-title">Рост</p>
                                    <p className="brif__field-family-title">Правша</p>
                                    <p className="brif__field-family-title">Левша</p>
                                </li>
                                {field.List.map((item) => {
                                    const itemKey = randomString(6, "aA#");

                                    return (
                                        <li className="brif__field-family-list-item" key={itemKey}>
                                            <p className="brif__field-family-list-item-inner">
                                                <span className="brif__field-family-title">
                                                    Член семьи
                                                </span>
                                                <input
                                                    onChange={(e) => {
                                                        updateTextFieldKey(e, item, "Name");
                                                    }}
                                                    type="text"
                                                    className="brif__field-text-input"
                                                    defaultValue={item.Name}
                                                    placeholder="Имя"
                                                />
                                            </p>
                                            <p className="brif__field-family-list-item-inner">
                                                <span className="brif__field-family-title">
                                                    Возраст
                                                </span>
                                                <input
                                                    onChange={(e) => {
                                                        updateTextFieldKey(e, item, "Age");
                                                    }}
                                                    type="text"
                                                    className="brif__field-text-input"
                                                    defaultValue={item.Age}
                                                />
                                            </p>
                                            <p className="brif__field-family-list-item-inner">
                                                <span className="brif__field-family-title">Рост</span>
                                                <input
                                                    onChange={(e) => {
                                                        updateTextFieldKey(e, item, "Height");
                                                    }}
                                                    type="text"
                                                    className="brif__field-text-input"
                                                    defaultValue={item.Height}
                                                />
                                            </p>
                                            <RadioButtons
                                                name={itemKey}
                                                field={item}
                                                fieldKey="Hand"
                                                updateSelectField={updateSelectField}
                                                type="2"
                                            />
                                            {/* <p className="brif__field-family-list-item-inner">
                        <span className="brif__field-family-title">Левша</span>
                        <label>
                          <input type="radio" name={itemKey} onClick={() => updateSelectField("Левша", item, "Hand")} defaultChecked={item.Hand === "Левша" ? true : false} />
                          <span>
                            <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M14.5 28C18.0804 28 21.5142 26.5777 24.0459 24.0459C26.5777 21.5142 28 18.0804 28 14.5C28 10.9196 26.5777 7.4858 24.0459 4.95406C21.5142 2.42232 18.0804 1 14.5 1C10.9196 1 7.4858 2.42232 4.95406 4.95406C2.42232 7.4858 1 10.9196 1 14.5C1 18.0804 2.42232 21.5142 4.95406 24.0459C7.4858 26.5777 10.9196 28 14.5 28Z"
                                stroke="#404AF2"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path d="M9.00015 14.0292L13.4374 18.4663" stroke="#404AF2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M13.5617 18.5909L21.591 10.5616" stroke="#404AF2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </span>
                        </label>
                      </p>
                      <p className="brif__field-family-list-item-inner">
                        <span className="brif__field-family-title">Правша</span>
                        <label>
                          <input type="radio" name={itemKey} onClick={() => updateSelectField("Правша", item, "Hand")} defaultChecked={item.Hand === "Правша" ? true : false} />
                          <span>
                            <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M14.5 28C18.0804 28 21.5142 26.5777 24.0459 24.0459C26.5777 21.5142 28 18.0804 28 14.5C28 10.9196 26.5777 7.4858 24.0459 4.95406C21.5142 2.42232 18.0804 1 14.5 1C10.9196 1 7.4858 2.42232 4.95406 4.95406C2.42232 7.4858 1 10.9196 1 14.5C1 18.0804 2.42232 21.5142 4.95406 24.0459C7.4858 26.5777 10.9196 28 14.5 28Z"
                                stroke="#404AF2"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path d="M9.00015 14.0292L13.4374 18.4663" stroke="#404AF2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M13.5617 18.5909L21.591 10.5616" stroke="#404AF2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </span>
                        </label>
                      </p> */}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                );
            }

            // Markup Fields
            if (field.__component === "global.start-section") {
                return (
                    <h2 className="brif__block-title" id={fieldName} key={fieldName}>
                        / {field.Title}
                    </h2>
                );
            }

            if (field.__component === "global.big-title") {
                return (
                    <h3 className="brif__section-title" key={fieldName}>
                        <span>{field.Value}</span>
                    </h3>
                );
            }

            if (field.__component === "global.enter") {
                return <div className="brif__line" key={fieldName}></div>;
            }

            if (field.__component === "global.end-section") {
                return <div className="brif__end" key={fieldName}></div>;
            }

            if (field.__component === "global.rooms-field") {
                console.log(field);
                return (
                    <RoomsComponent
                        key={fieldName}
                        field={field}
                        updateField={updateTextFieldKey}
                        updateListRooms={updateListRooms}
                    />
                );
            }
        });
    };

    window.addEventListener("beforeprint", (event) => {
        document.querySelectorAll("input").forEach((input) => {
            input.placeholder = "";
        });
    });

    return (
        <>
            {!loading ? (
                <div className="brif">
                    <div
                        className="brif__intro"
                        style={{
                            background: `url('${process.env.REACT_APP_STRAPI_URL}${content.Preview.data.attributes.url}') no-repeat`,
                            backgroundSize: "cover",
                        }}
                    >
                        <div className="container">
                            <img
                                src="/images/logo.png"
                                alt="Лого"
                                className="brif__intro-img"
                            />
                        </div>
                        <div className="brif__intro-content container">
                            <h1 className="brif__intro-title">{content.Title}</h1>
                            <button
                                onClick={copyToClipboard}
                                type="button"
                                className="brif__intro-btn"
                            >
                                Поделиться ссылкой на бриф
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect
                                        x="0.558824"
                                        y="0.558824"
                                        width="18.8824"
                                        height="18.8824"
                                        rx="2.23529"
                                        fill="#404AF2"
                                    />
                                    <rect
                                        x="0.558824"
                                        y="0.558824"
                                        width="18.8824"
                                        height="18.8824"
                                        rx="2.23529"
                                        stroke="#404AF2"
                                        strokeWidth="1.11765"
                                    />
                                    <path
                                        d="M8 5H6C5.44772 5 5 5.44772 5 6V14C5 14.5523 5.44772 15 6 15H14C14.5523 15 15 14.5523 15 14V12"
                                        stroke="white"
                                        strokeWidth="0.8"
                                        strokeLinecap="round"
                                    />
                                    <path
                                        d="M12 5H15V8"
                                        stroke="white"
                                        strokeWidth="0.8"
                                        strokeLinecap="round"
                                    />
                                    <path
                                        d="M9.98032 9.454C9.82411 9.61021 9.82411 9.86348 9.98032 10.0197C10.1365 10.1759 10.3898 10.1759 10.546 10.0197L9.98032 9.454ZM14.7172 4.71716L9.98032 9.454L10.546 10.0197L15.2828 5.28284L14.7172 4.71716Z"
                                        fill="white"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                    {data.Dogovor_sostoyanie === "Показать" && (
                        <PreBlock
                            dog_number={data.Dogovor_nomer}
                            dog_date={data.Dogovor_date}
                            updateInputData={updateInputData}
                        />
                    )}
                    <div className="brif__blocks container">
                        {renderFields(data.Fields)}
                    </div>
                </div>
            ) : (
                <h2>Загружается...</h2>
            )}
        </>
    );
};

export default Brif;
