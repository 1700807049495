import Textarea from "../ui/Textarea/Textarea";

const TextAreaComponent = (props) => {
  const field = props.field;
  const updateField = props.updateField;
  const label = props.label;

  return (
    <div className="brif__field">
      <label className="brif__field-text brif__field--inner">
        <span className="brif__field-text-label">
          {label ? label : field.Label}
        </span>
        <Textarea
          onChange={(e) => {
            updateField(e, field);
          }}
          className="brif__field-text-input"
          defaultValue={field.Value}
          placeholder={field.Placeholder}
        />
      </label>
    </div>
  );
};

export default TextAreaComponent;
