import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { get_content, randomString, register_brif } from "../services/service";

const Registration = (props) => {
    const [email, setEmail] = useState(null);
    const [checked, setChecked] = useState(null);
    const inputTextRef = useRef(null);
    const inputCheckboxRef = useRef(null);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const { slug } = props.data;
    let fullCheck = true; // eslint-disable-next-line
    const navigate = useNavigate();

    useEffect(() => {
        const getData = async () => {
            console.log(props);
            const res = await get_content(`${slug}-registration`);

            if (res.data !== null) {
                setData(res.data.attributes);
                setLoading(false);
            }
        };

        getData();
    }, []);

    if (!loading) {
        console.log(data);
    }

    const validateEmail = (email) => {
        return email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    };

    const onSend = async () => {
        // Generate UID
        const UID = randomString(12, "aA#");

        // Validate Email
        if (validateEmail(inputTextRef.current.value)) {
            setEmail(inputTextRef.current.value);
        } else {
            setEmail(false);
            fullCheck = false;
        }

        // Validate checkbox
        setChecked(inputCheckboxRef.current.checked);
        fullCheck = inputCheckboxRef.current.checked;

        if (validateEmail(inputTextRef.current.value) && inputCheckboxRef.current.checked) {
            await register_brif(inputTextRef.current.value, UID, slug);
            navigate(`/${slug}/${UID}`);
        }
    };

    return (
        <>
            {!loading ? (
                <div className="reg">
                    <div
                        className="reg__intro"
                        style={{
                            background: `url('${process.env.REACT_APP_STRAPI_URL}${data.Preview.data.attributes.url}') no-repeat`,
                            backgroundSize: "cover",
                        }}
                    >
                        <div className="container">
                            <img src="/images/logo.png" alt="Лого" className="reg__intro-img" />
                        </div>
                    </div>
                    <div className="reg__body container">
                        <div className="reg__content">
                            <h2 className="reg__title">/{data.Title}</h2>
                            <p className="reg__subtitle">{data.Text}</p>
                        </div>
                        <div className="reg__form">
                            <p className="reg__form-title">{data.Subtitle}</p>
                            <input ref={inputTextRef} type="text" className={"reg__form-input" + (email === false ? " error" : "")} placeholder="Email" />
                            <div className="reg__form-checkbox">
                                <label className="reg__form-checkbox-label">
                                    <input ref={inputCheckboxRef} type="checkbox" className={"reg__form-checkbox-input" + (checked === false ? " error" : "")} />
                                    <span>
                                        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.50018 8.02911L5.9374 12.4662" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M6.06171 12.5911L14.0911 4.56171" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </span>
                                </label>
                                <p className="reg__form-checkbox-text">
                                    я согласен(а) с <a href="#">политикой конфиденциальности</a>
                                </p>
                            </div>
                            <button onClick={onSend} className="reg__form-submit" type="button">
                                Перейти к заполнению
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                ""
            )}
        </>
    );
};

export default Registration;
