const PreBlock = (props) => {
    let { dog_number, dog_date, updateInputData } = props;

    if (dog_number === null) {
        dog_number = ""
    }

    if (dog_date === null) {
        dog_date = ""
    }

    return (
        <p className="brif__pre container">Договор №&nbsp;<input
            onChange={(e) => {
                updateInputData(e, 'Dogovor_nomer')
            }}
            defaultValue={dog_number}
            style={{ width: `${dog_number.length < 1 ? "10" : dog_number.length}ch` }}
            className="brif__pre-input" type="text" /> от&nbsp;<input
                onChange={(e) => {
                    updateInputData(e, 'Dogovor_date')
                }}
                defaultValue={dog_date}
                style={{ width: `${dog_date.length < 1 ? "10" : dog_date.length}ch` }}
                className="brif__pre-input" type="text" /></p>
    )
}

export default PreBlock;