import axios from "axios";

let strapi_token =
  "47ef063981c45409c978135e400aa314f15200299d3cb4d37875c0f3b8e2214fe58432d6730a014fd14521e8918e04e90c30bb8e08f9006cc237ab6679367f88f02edb761b8804c2821aae282ba9e5e4aaf30f71d26e8634095ff46c0bae5a0d5e613cc85efb6d24e736e5306e038d6864ff7fbc1ffad833d70c492162fe83d3";

let URL = "http://194.67.90.57:1337";

const local_token =
  "faa00de41bffeeaa4011c0bb3f5e6c289ce15705eb3b09b90ffdf14330f4044cebcba2836cd9e38193487cd4be3419482900a51562671b1b4a0baab66822a374e7cc4e979a294fbdb93ab6f815b08a64a6c3427e848beb5c3cdfad257bd067c7ce39921fb98d30eb6dfd70071118ef8d71c52a8dd1266071c7cdc0f3304d24d3";

const local_url = "http://localhost:1337";

strapi_token = process.env.REACT_APP_STRAPI_TOKEN;
URL = process.env.REACT_APP_STRAPI_URL;

export const register_brif = async (email, uid, slug) => {
  let data = null;

  await axios
    .get(
      `${URL}/api/${slug}-template?populate[0]=Fields.List&populate[1]=Fields.List.Image&populate[2]=Fields.Rooms`,
      {
        headers: {
          Authorization: `Bearer ${strapi_token}`,
        },
      },
    )
    .then((response) => {
      // console.log(response.data);
      data = response.data.data.attributes;
    })
    .catch((error) => {
      console.log(error);
    });

  data.Email = email;
  data.UID = uid;

  // Get ID assets. Strapi so interesting...

  data.Fields.map((field) => {
    if (
      field.__component === "global.images-field" ||
      field.__component === "global.colors-field"
    ) {
      /* console.log(field); */
      field.List.map((item) => {
        item.Image = item.Image.data.map((image) => {
          /* image.id = image.data.id; */
          return { id: image.id };
        });
        /* item.Image.id = item.Image.data.id; */
        return item;
      });
    }

    return field;
  });

  await axios
    .post(
      `${URL}/api/${slug}-leads`,
      {
        data: data,
      },
      {
        headers: {
          Authorization: `Bearer ${strapi_token}`,
        },
      },
    )
    .then((response) => {
      console.log(response);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const init_brif = async (UID, slug) => {
  let data = null;
  await axios
    .get(
      `${URL}/api/${slug}-leads?filters[UID][$eq]=${UID}&populate[0]=Fields.List&populate[1]=Fields.List.Image&populate[2]=Fields.Rooms`,
      {
        headers: {
          Authorization: `Bearer ${strapi_token}`,
        },
      },
    )
    .then((response) => {
      // console.log(response.data);
      data = response.data;

      data.data[0].attributes.Fields.map((field) => {
        if (
          field.__component === "global.images-field" ||
          field.__component === "global.colors-field"
        ) {
          /* console.log(field); */
          field.List.map((item) => {
            item.Image = item.Image.data.map((image) => image);
            return item;
          });
        }

        return field;
      });
    })
    .catch((error) => {
      console.log(error);
    });

  return data;
};

export const init_template = async (slug) => {
  let data = null;
  await axios
    .get(
      `${URL}/api/${slug}-template?populate[0]=Fields.List&populate[1]=Fields.List.Image&populate[2]=Fields.Rooms`,
      {
        headers: {
          Authorization: `Bearer ${strapi_token}`,
        },
      },
    )
    .then((response) => {
      // console.log(response.data);
      data = response.data;

      data.data.attributes.Fields.map((field) => {
        if (
          field.__component === "global.images-field" ||
          field.__component === "global.colors-field"
        ) {
          console.log(field);
          field.List.map((item) => {
            item.Image = item.Image.data.map((image) => image);
            return item;
          });
        }

        return field;
      });
    })
    .catch((error) => {
      console.log(error);
    });

  return data;
};

export const update_brif = async (data, id, slug) => {
  await axios
    .put(
      `${URL}/api/${slug}-leads/${id}`,
      {
        data: data,
      },
      {
        headers: {
          Authorization: `Bearer ${strapi_token}`,
        },
      },
    )
    .then((response) => {
      console.log(response);
    })
    .catch((error) => {
      console.log(error);
    });
};

// export const update_brif_field = async (field, id) => {
//     await axios.put(`${URL}/api/brifs/${id}`, {
//         data: {
//             Fields: [field]
//         }
//     },
//         {
//             headers: {
//                 Authorization: `Bearer ${strapi_token}`
//             }
//         }).then((response) => {
//             console.log(response);
//         }).catch((error) => {
//             console.log(error)
//         })
// }

// export const update_template_brif = async (data) => {
//     await axios.put(`${URL}/api/brif-template?populate[0]=Fields.List&populate[1]=Fields.List.Image`, {
//         data: data
//     },
//         {
//             headers: {
//                 Authorization: `Bearer ${strapi_token}`
//             }
//         }
//     ).then((response) => {
//         console.log(response);
//         // data = response.data.data.attributes
//     }).catch((error) => {
//         console.log(error.response)
//     })
// }

export const get_content = async (slug) => {
  let data = null;
  await axios
    .get(`${URL}/api/${slug}?populate=*`, {
      headers: {
        Authorization: `Bearer ${strapi_token}`,
      },
    })
    .then((response) => {
      // console.log(response.data);
      data = response.data;
    })
    .catch((error) => {
      console.log(error);
    });

  return data;
};

export const randomString = (length, chars) => {
  var mask = "";
  if (chars.indexOf("a") > -1) mask += "abcdefghijklmnopqrstuvwxyz";
  if (chars.indexOf("A") > -1) mask += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  if (chars.indexOf("#") > -1) mask += "0123456789";
  if (chars.indexOf("!") > -1) mask += "!@#$%^&*()_-+=[{]};:>|./?";
  var result = "";
  for (var i = length; i > 0; --i)
    result += mask[Math.round(Math.random() * (mask.length - 1))];
  return result;
};
