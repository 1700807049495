import React, { useEffect, useRef } from "react";
import { calculateHeight } from "../../../utils/formUtils";

export default function Textarea(props) {
  const textareaRef = useRef(null);
  useEffect(() => {
    calculateHeight(textareaRef.current);
  }, []);

  const handleChange = (e) => {
    // Вызываем функцию из props
    if (props.onChange) {
      props.onChange(e);
    }
    // Вызываем calculateHeight
    calculateHeight(textareaRef.current);
  };

  return (
    <textarea
      rows={props.rows ? props.rows : 2}
      ref={textareaRef}
      onChange={handleChange}
      className={props.className}
      defaultValue={props.defaultValue}
      placeholder={props.placeholder}
    ></textarea>
  );
}
