import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import Registration from './components/registration';
import Brif from './components/brif';
import Template from "./components/template";

const router = createBrowserRouter([
    {
        path: "*",
        element: <>
            <h1>Похоже такой страницы не существует...</h1>
        </>
    },
    {
        path: "/architecture",
        element: <Registration data={{
            slug: "architecture",
        }} />,
    },
    {
        path: "/architecture/:UID",
        element: <Brif data={{
            slug: "architecture",
        }} />,
    },
    {
        path: "/design",
        element: <Registration data={{
            slug: "design",
        }} />,
    },
    {
        path: "/design/:UID",
        element: <Brif data={{
            slug: "design",
        }} />,
    },
    {
        path: "/engineering",
        element: <Registration data={{
            slug: "engineering",
        }} />,
    },
    {
        path: "/engineering/:UID",
        element: <Brif data={{
            slug: "engineering",
        }} />,
    },
    {
        path: "/architecture-template",
        element: <Template data={{
            slug: "architecture",
        }} />,
    },
    {
        path: "/design-template",
        element: <Template data={{
            slug: "design",
        }} />,
    },
    {
        path: "/engineering-template",
        element: <Template data={{
            slug: "engineering",
        }} />,
    },
]);

const App = () => {
    return (
        <div className="page">
            <RouterProvider router={router} />
        </div>
    )
}

export default App;