import RadioButtons from "../ui/RadioButtons/RadioButtons";

const CheckboxComponent = (props) => {
    const field = props.field
    const fieldName = props.fieldName
    const updateSelectField = props.updateSelectField
    const updateCheckboxFieldText = props.updateCheckboxFieldText
    return (
        <div className="brif__field">
            <div className="brif__field-checkbox brif__field--inner">
                <span className="brif__field-checkbox-label">{field.Label}</span>
                <div className="brif__field-checkbox-controls">
                    <RadioButtons name={fieldName} field={field} fieldKey="Checked" updateSelectField={updateSelectField} type="1" />
                </div>
                {field.Text_label !== null ? (
                    <div className="brif__field-checkbox-text">
                        <input
                            onChange={(e) => {
                                updateCheckboxFieldText(e, field);
                            }}
                            type="text"
                            className="brif__field-text-input"
                            defaultValue={field.Text}
                            placeholder={field.Text_label}
                        />
                    </div>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
}

export default CheckboxComponent;