import React, { useEffect, useState } from "react";
import { randomString } from "../../services/service";
import Textarea from "../ui/Textarea/Textarea";
import RoomComponent from "./RoomComponent";

export default function RoomsComponent({
  field,
  updateField,
  updateListRooms,
}) {
  // Используем useState для управления состоянием field
  const [rooms, setRooms] = useState(field);

  // Функция для добавления нового помещения
  const addRoom = () => {
    const newRoom = {
      Size: null,
      Name: "",
      Number_room: null,
      Description: "",
    };
    const updatedField = {
      ...rooms,
      Rooms: [...rooms.Rooms, newRoom],
    };
    setRooms(updatedField);
    updateListRooms(field, updatedField);
  };

  // Функция для удаления помещения
  const removeRoom = (roomIndex) => {
    const updatedRooms = rooms.Rooms.filter(
      (room, index) => index !== roomIndex,
    );
    const updatedField = { ...rooms, Rooms: updatedRooms };
    setRooms(updatedField);
    updateListRooms(field, updatedField);
  };

  return (
    <div>
      {field.Rooms &&
        field.Rooms.map((room, index) => (
          <RoomComponent
            key={randomString(10, "aA#")}
            room={room}
            index={index}
            removeRoom={removeRoom}
            updateField={updateField}
          />
        ))}
      <div className="brif__field hide-on-print">
        <div className="brif__buttons brif__buttons--center brif__field--inner">
          <button className="brif-btn brif-btn--add" onClick={addRoom}>
            Добавить помещение
          </button>
        </div>
      </div>
    </div>
  );
}
