import Textarea from "../ui/Textarea/Textarea";


const TextComponent = (props) => {
    const field = props.field
    const updateField = props.updateField
    return (
        <div className="brif__field">
            <label className="brif__field-text brif__field--inner">
                <span className="brif__field-text-label">{field.Label}</span>
                <Textarea
                    onChange={(e) => {
                        updateField(e, field);
                    }}
                    className="brif__field-text-input"
                    defaultValue={field.Value}
                    placeholder={field.Placeholder}
                    rows={1}
                />
            </label>
        </div>
    )
}

export default TextComponent;