import React from "react";

export default function GalleryCheckBox({
  item,
  image_data,
  fieldName,
  updateCheckboxField,
}) {
  // let firstImage = `${process.env.REACT_APP_STRAPI_URL}${image_data[0].attributes.url}`;

  return (
    <li className="brif__field-images-list-item">
      {image_data.map((image, index) => {
        let urlImage = `${process.env.REACT_APP_STRAPI_URL}${image.attributes.url}`;
        return (
          <a
            key={image.id}
            href={urlImage}
            data-fancybox={"gallery-images-" + item.id}
            data-caption={image.attributes.caption}
            className={
              index == 0
                ? "brif__field-images-label-pic"
                : "brif__field-images-label-pic brif__field-images-label-pic-hidden"
            }
          >
            <img src={urlImage} alt="Image" />
          </a>
        );
      })}
      <label className="brif__field-images-label">
        <input
          type="checkbox"
          onClick={(e) => updateCheckboxField(item, e.target.checked)}
          defaultChecked={item.Checked}
        />
        <span className="brif__field-images-label-content">
          <svg
            width="29"
            height="29"
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.5 28C18.0804 28 21.5142 26.5777 24.0459 24.0459C26.5777 21.5142 28 18.0804 28 14.5C28 10.9196 26.5777 7.4858 24.0459 4.95406C21.5142 2.42232 18.0804 1 14.5 1C10.9196 1 7.4858 2.42232 4.95406 4.95406C2.42232 7.4858 1 10.9196 1 14.5C1 18.0804 2.42232 21.5142 4.95406 24.0459C7.4858 26.5777 10.9196 28 14.5 28Z"
              stroke="#404AF2"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.00015 14.0292L13.4374 18.4663"
              stroke="#404AF2"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.5617 18.5909L21.591 10.5616"
              stroke="#404AF2"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          {image_data[0].attributes.caption}
        </span>
      </label>
    </li>
  );
}
