import React from "react";
import { randomString } from "../../services/service";
import Textarea from "../ui/Textarea/Textarea";

const CheckBoxesComponent = (props) => {
    const field = props.field
    const fieldName = props.fieldName
    const updateSelectField = props.updateSelectField
    const updateCheckboxFieldText = props.updateCheckboxFieldText
    const list = field.List
    let count = 0;
    return (
        <div className="brif__field" key={fieldName}>
            <div className="brif__field-checkbox brif__field-checkbox--list brif__field--inner">
                <span className="brif__field-checkbox-label brif__field-checkbox--list-label">{field.Label}</span>
                <div className="brif__field-checkbox--list-inner">
                    {list.map((item) => {
                        const itemKey = randomString(6, "aA#");
                        if (count === 3) {
                            count = 0;
                        } else {
                            count = count + 1;
                        }
                        let check = item.Checked;
                        //check = "Пусто";
                        return (
                            <React.Fragment key={itemKey}>
                                <label className={`brif__field-checkbox--list-inner-label ${item.Text_label !== null && "brif__field-checkbox--list-inner-label--auto-width"}`}>
                                    <input
                                        type="checkbox"
                                        name={fieldName}
                                        onClick={(e) => updateSelectField(e.target.checked ? "Да" : "Нет", item, "Checked")}
                                        defaultChecked={check === "Да" ? true : false}
                                    />
                                    <span>
                                        <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M14.5 28C18.0804 28 21.5142 26.5777 24.0459 24.0459C26.5777 21.5142 28 18.0804 28 14.5C28 10.9196 26.5777 7.4858 24.0459 4.95406C21.5142 2.42232 18.0804 1 14.5 1C10.9196 1 7.4858 2.42232 4.95406 4.95406C2.42232 7.4858 1 10.9196 1 14.5C1 18.0804 2.42232 21.5142 4.95406 24.0459C7.4858 26.5777 10.9196 28 14.5 28Z"
                                                stroke="#404AF2"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path d="M9.00015 14.0292L13.4374 18.4663" stroke="#404AF2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M13.5617 18.5909L21.591 10.5616" stroke="#404AF2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        {item.Label}
                                    </span>
                                </label>
                                {item.Text_label !== null && (
                                    <div className={`brif__field-checkbox--list-inner-text`} key={fieldName}>
                                        <Textarea
                                            onChange={(e) => {
                                                updateCheckboxFieldText(e, item);
                                            }}
                                            className="brif__field-text-input"
                                            defaultValue={item.Text}
                                            placeholder={item.Text_label}
                                        />
                                    </div>
                                )}
                            </React.Fragment>
                        );
                    })}
                    {field.Text_placeholder !== null && (
                        <div className="brif__field-checkbox--list-inner-textarea">
                            <Textarea
                                onChange={(e) => {
                                    updateCheckboxFieldText(e, field);
                                }}
                                className="brif__field-text-input"
                                defaultValue={field.Text}
                                placeholder={field.Text_placeholder}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default CheckBoxesComponent;