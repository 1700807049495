import React, { useState } from "react";

export default function RadioButtons({
  name,
  field,
  fieldKey,
  updateSelectField,
  type,
}) {
  const [selectedValue, setSelectedValue] = useState(field[fieldKey]);

  const handleRadioChange = (value) => {
    setSelectedValue(value);
    updateSelectField(value, field, fieldKey);
  };

  const handleRadioClick = (value) => {
    if (selectedValue === value) {
      setSelectedValue(null);
      updateSelectField("Пусто", field, fieldKey);
    }
  };
  if (type == 1) {
    return (
      <>
        <label>
          <input
            type="radio"
            name={name}
            onChange={() => handleRadioChange("Да")}
            onClick={() => handleRadioClick("Да")}
            checked={selectedValue === "Да"}
          />
          <span>
            <svg
              width="29"
              height="29"
              viewBox="0 0 29 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.5 28C18.0804 28 21.5142 26.5777 24.0459 24.0459C26.5777 21.5142 28 18.0804 28 14.5C28 10.9196 26.5777 7.4858 24.0459 4.95406C21.5142 2.42232 18.0804 1 14.5 1C10.9196 1 7.4858 2.42232 4.95406 4.95406C2.42232 7.4858 1 10.9196 1 14.5C1 18.0804 2.42232 21.5142 4.95406 24.0459C7.4858 26.5777 10.9196 28 14.5 28Z"
                stroke="#404AF2"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.00015 14.0292L13.4374 18.4663"
                stroke="#fff"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13.5617 18.5909L21.591 10.5616"
                stroke="#fff"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Да
          </span>
        </label>
        <label>
          <input
            type="radio"
            name={name}
            onChange={() => handleRadioChange("Нет")}
            onClick={() => handleRadioClick("Нет")}
            checked={selectedValue === "Нет"}
          />
          <span>
            <svg
              width="29"
              height="29"
              viewBox="0 0 29 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.5 28C18.0804 28 21.5142 26.5777 24.0459 24.0459C26.5777 21.5142 28 18.0804 28 14.5C28 10.9196 26.5777 7.4858 24.0459 4.95406C21.5142 2.42232 18.0804 1 14.5 1C10.9196 1 7.4858 2.42232 4.95406 4.95406C2.42232 7.4858 1 10.9196 1 14.5C1 18.0804 2.42232 21.5142 4.95406 24.0459C7.4858 26.5777 10.9196 28 14.5 28Z"
                stroke="#404AF2"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.00015 14.0292L13.4374 18.4663"
                stroke="#fff"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13.5617 18.5909L21.591 10.5616"
                stroke="#fff"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Нет
          </span>
        </label>
      </>
    );
  }

  if (type == 2) {
    return (
      <>
        <p className="brif__field-family-list-item-inner">
          <span className="brif__field-family-title">Левша</span>
          <label>
            <input
              type="radio"
              name={name}
              onChange={() => handleRadioChange("Левша")}
              onClick={() => handleRadioClick("Левша")}
              checked={selectedValue === "Левша"}
            />
            <span>
              <svg
                width="29"
                height="29"
                viewBox="0 0 29 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.5 28C18.0804 28 21.5142 26.5777 24.0459 24.0459C26.5777 21.5142 28 18.0804 28 14.5C28 10.9196 26.5777 7.4858 24.0459 4.95406C21.5142 2.42232 18.0804 1 14.5 1C10.9196 1 7.4858 2.42232 4.95406 4.95406C2.42232 7.4858 1 10.9196 1 14.5C1 18.0804 2.42232 21.5142 4.95406 24.0459C7.4858 26.5777 10.9196 28 14.5 28Z"
                  stroke="#404AF2"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.00015 14.0292L13.4374 18.4663"
                  stroke="#404AF2"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.5617 18.5909L21.591 10.5616"
                  stroke="#404AF2"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </label>
        </p>
        <p className="brif__field-family-list-item-inner">
          <span className="brif__field-family-title">Правша</span>
          <label>
            <input
              type="radio"
              name={name}
              onChange={() => handleRadioChange("Правша")}
              onClick={() => handleRadioClick("Правша")}
              checked={selectedValue === "Правша"}
            />
            <span>
              <svg
                width="29"
                height="29"
                viewBox="0 0 29 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.5 28C18.0804 28 21.5142 26.5777 24.0459 24.0459C26.5777 21.5142 28 18.0804 28 14.5C28 10.9196 26.5777 7.4858 24.0459 4.95406C21.5142 2.42232 18.0804 1 14.5 1C10.9196 1 7.4858 2.42232 4.95406 4.95406C2.42232 7.4858 1 10.9196 1 14.5C1 18.0804 2.42232 21.5142 4.95406 24.0459C7.4858 26.5777 10.9196 28 14.5 28Z"
                  stroke="#404AF2"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.00015 14.0292L13.4374 18.4663"
                  stroke="#404AF2"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.5617 18.5909L21.591 10.5616"
                  stroke="#404AF2"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </label>
        </p>
      </>
    );
  }
}
