import React from "react";
import Fancybox from "../widgets/Fancybox/Fancybox";
import { randomString } from "../../services/service";
import GalleryCheckBox from "./GalleryCheckBox";

export default function GalleryCheckBoxesComponent({
    field,
    fieldName,
    updateCheckboxField,
}) {
    return (
        <div className="brif__field">
            <div className="brif__field--inner brif__field-images">
                <h4 className="brif__field-text-label">{field.Label}</h4>
                <Fancybox
                    options={{
                        Carousel: {
                            infinite: true,
                        },
                    }}
                >
                    <ul className="brif__field-images-list">
                        {field.List.map((item) => {
                            const itemKey = randomString(6, "aA#");
                            const image_data = item.Image;
                            if (image_data) {
                                return (
                                    <GalleryCheckBox
                                        key={itemKey}
                                        item={item}
                                        image_data={image_data}
                                        fieldName={fieldName}
                                        updateCheckboxField={updateCheckboxField}
                                    />
                                );
                            }
                        })}
                    </ul>
                </Fancybox>
            </div>
        </div>
    );
}
